(function () {
    angular.module('informaApp')
        .component('newFeatureFilePreview', {
            templateUrl: 'components/new-feature-view/new-feature-file-preview/template.html',
            controller: NewFeatureFilePreview,
            transclude: {
                pre: '?preElement',
                post: '?postElement',
            },
            bindings: {
                source: '<'
            }
        });

    function NewFeatureFilePreview(NewFeatureFileTypes) {
        this.fileTypes = NewFeatureFileTypes;
    }
})();